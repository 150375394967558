import React, {useState, useEffect, Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {mergeStyleSets} from '@fluentui/react/lib/Styling';
import {DefaultButton, SearchBox} from '@fluentui/react/';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {buttonStyle} from '../../../common/calendar/ButtonStyles';
import {faFilter} from '@fortawesome/pro-regular-svg-icons';
import {TeachingBubble} from '@fluentui/react/lib/TeachingBubble';
import {setFilter} from '../../../../redux/machines/machines.actions';
import {stationOptions} from '../../../../utils/constants';
import StaffRoleSelector from '../filterSelector/StaffRoleSelector';
import ToggleableSelector from '../filterSelector/ToggleableSelector';
import SimpleToggleButton from '../../../common/SimpleToggleButton';

const classNames = mergeStyleSets({
    unSelectedIcon: {
        marginRight: 2,
        fontSize: 16,
        color: '#006CAD',
    },
    iconNumber: {
        position: 'absolute',
        paddingLeft: '3px',
        paddingRight: '3px',
        borderRadius: '50%',
        marginTop: '-15px',
        marginLeft: '15px',
        color: '#FFFFFF',
        fontSize: 10,
        fontWeight: 'bold',
        backgroundColor: '#006CAD',
    },
    heading: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#000000',
    },
    sectionContainer: {
        marginTop: 35,
    },
    lastSection: {
        marginTop: 35,
        marginBottom: 50,
    },
});

const PlanningPageFilter = ({scrollY}) => {
    const dispatch = useDispatch();
    const filter = useSelector((state) => state?.machine?.filter);
    const defaultData = useSelector((state) => state.defaultData);
    const techDepartment = useSelector((state) => state?.machine?.filter.techDepartment);
    const displayStaffView = useSelector((state) => state.machine.displayStaffOnPlanning);

    const [showBubble, setShowBubble] = useState(false);
    const currentSearchKey = displayStaffView ? 'staffNames' : 'machineNames';
    console.log('PlanningPageFilter.js, filter:', scrollY);
    const componentStyles = (displayStaffView) => ({
        teachingBubble: {
            root: {
                position: 'fixed',
                marginTop: displayStaffView ? `${140}px` : `${100}px`,
                width: 600,
            },
            subComponentStyles: {
                callout: {
                    beak: {
                        position: 'fixed',
                        marginTop: displayStaffView ? `${-140}px` : `${-100}px`,
                    },
                },
            },
        },
        searchBox: {
            root: {
                border: 'none',
                backgroundColor: '#FFFFFF',
                margin: '-10px -10px 0 -5px',
                height: '56px',
                ':after': {
                    border: 'none',
                },
                width: '180px',
            },
            iconContainer: {
                display: 'none',
            },
            clearButton: {
                marginLeft: '-50px',
            },
        },
    });

    const countSelectorNumbers = () => {
        let count = 0;
        const countKey = displayStaffView ? ['techArea', 'staffRole'] : ['selectedRegion', 'techArea', 'selectedMachineSizeGroup'];
        const currentFilter = filter[techDepartment];
        Object.keys(currentFilter).forEach((key) => {
            if (countKey.includes(key)) {
                if (key === 'staffRole') {
                    count += currentFilter[key]?.filter((item) => item.isOn)?.length;
                } else if (key === 'selectedRegion') {
                    count += currentFilter['availableRegion']?.filter((item) => currentFilter[key].includes(item))?.length || 0;
                } else if (key === 'techArea') {
                    count += currentFilter[key]?.length;
                } else if (key === 'selectedMachineSizeGroup') {
                    count += currentFilter['availableMachineSizeGroup']?.filter((item) => currentFilter[key].includes(item))?.length || 0;
                }
            }
        });
        return count;
    };

    return (
        <div>
            <div id='filter-button'>
                <DefaultButton onClick={() => setShowBubble(!showBubble)} styles={{...buttonStyle(false)}}>
                    <FontAwesomeIcon icon={faFilter} className={classNames.unSelectedIcon}/>
                    {countSelectorNumbers() > 0 &&
                        <span className={classNames.iconNumber}>{countSelectorNumbers()}</span>}
                </DefaultButton>
            </div>
            {showBubble && (
                <TeachingBubble
                    styles={componentStyles(displayStaffView).teachingBubble}
                    closeButtonAriaLabel='Close'
                    target='#filter-button'
                    onDismiss={() => setShowBubble(!showBubble)}
                    isWide={true}
                >
                    <div style={{margin: '11px'}}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '115%',
                            marginLeft: -10
                        }}>
                            <div>
                                <span
                                    className={classNames.heading}>{displayStaffView ? 'Staff names' : 'Machine names'}</span>
                                <div style={{marginTop: '20px'}}>
                                    <SearchBox
                                        styles={componentStyles(displayStaffView).searchBox}
                                        placeholder='Search'
                                        clearButtonProps={{
                                            style: {
                                                backgroundColor: '#FFFFFF',
                                                marginLeft: '20px',
                                            },
                                        }}
                                        value={filter[techDepartment][currentSearchKey] || ''}
                                        onChange={(e, value) => dispatch(setFilter({[techDepartment]: {[currentSearchKey]: value || ''}}))}
                                    />
                                    <hr style={{width: '145%', marginLeft: '-50px', marginTop: '-2px', opacity: 0.5}}/>
                                </div>
                            </div>
                            <div style={{marginLeft: 40}}>
                                <span className={classNames.heading}>Project names</span>
                                <div style={{marginTop: '20px'}}>
                                    <SearchBox
                                        styles={componentStyles(displayStaffView).searchBox}
                                        placeholder='Search'
                                        clearButtonProps={{
                                            style: {
                                                backgroundColor: '#FFFFFF',
                                                marginLeft: '20px',
                                            },
                                        }}
                                        value={filter[techDepartment]['projectNames'] || ''}
                                        onChange={(e, value) => dispatch(setFilter({[techDepartment]: {projectNames: value || ''}}))}
                                    />
                                    <hr style={{width: '150%', marginLeft: '-25px', marginTop: '-2px', opacity: 0.5}}/>
                                </div>
                            </div>
                        </div>
                        {!displayStaffView && (
                            <div className={classNames.sectionContainer}>
                                <span className={classNames.heading}>Region</span>
                                <ToggleableSelector
                                    filterKey='selectedRegion'
                                    techDepartment={filter.techDepartment}
                                    allOptions={[...defaultData.sectionOptions, {key: 999, text: 'Fag Udlejning'}]}
                                    availableOptions={
                                        filter[techDepartment].availableRegion.some((region) => [201, 202, 203].includes(region))
                                            ? [...filter[techDepartment].availableRegion.filter((region) => ![201, 202, 203].includes(region)), 999]
                                            : filter[techDepartment].availableRegion
                                    }
                                    selectedOptions={
                                        filter[techDepartment].selectedRegion.some((region) => [201, 202, 203].includes(region))
                                            ? [...filter[techDepartment].selectedRegion.filter((region) => ![201, 202, 203].includes(region)), 999]
                                            : filter[techDepartment].selectedRegion
                                    }
                                />
                            </div>
                        )}
                        <div className={classNames.sectionContainer}>
                            <span className={classNames.heading}>Disponent</span>
                            <ToggleableSelector
                                filterKey='techArea'
                                techDepartment={filter.techDepartment}
                                allOptions={stationOptions[techDepartment]['options']}
                                availableOptions={stationOptions[techDepartment]['options'].map((item) => item.key)}
                                selectedOptions={filter[techDepartment].techArea}
                            />
                        </div>
                        {displayStaffView ? (
                            <Fragment>
                                <div className={classNames.sectionContainer}>
                                    <span className={classNames.heading}>Personalets rolle</span>
                                    <StaffRoleSelector/>
                                </div>
                                <div className={classNames.lastSection}>
                                    <span className={classNames.heading}>Maskingruppering</span>
                                    <SimpleToggleButton toggle={filter[techDepartment].showStaffInMachines}
                                                        style={{marginTop: 20}}
                                                        onClick={() => dispatch(setFilter({[techDepartment]: {showStaffInMachines: !filter[techDepartment].showStaffInMachines}}))}/>
                                </div>
                            </Fragment>
                        ) : (
                            <div className={classNames.lastSection}>
                                <span className={classNames.heading}>Sats grupper</span>
                                <ToggleableSelector
                                    filterKey='selectedMachineSizeGroup'
                                    techDepartment={filter.techDepartment}
                                    allOptions={defaultData.sizeGroupOptions}
                                    availableOptions={filter[techDepartment].availableMachineSizeGroup}
                                    selectedOptions={filter[techDepartment].selectedMachineSizeGroup}
                                />
                            </div>
                        )}
                    </div>
                </TeachingBubble>
            )}
        </div>
    );
};

export default PlanningPageFilter;
